import { Box, Typography } from '@mui/material';
import Logo from './Logo';

export default function LogoSection() {
    return <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo disabledLink /> 
        <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ color: 'text.primary' }}>
                {process.env.REACT_APP_PRODUCT_NAME}
            </Typography>
        </Box>
    </Box>;
}
import { Box } from '@mui/material';

const loadingImg = '/static/loading.svg';

const Loading = () => (
    <Box className="spinner" sx={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <img src={loadingImg} alt="Loading..." />
    </Box>
);

export default Loading;

import { Card, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import Label from 'components/Label';
import Scrollbar from 'components/Scrollbar';
import { PhotobookMetadata } from 'types/PhotobookData';

export const PhotobookGrid = (props: {
    photobookSelected: (pb: PhotobookMetadata | false) => void;
    photobooks: PhotobookMetadata[];
}) => {
    const { photobookSelected, photobooks } = props;

    return (
        <Card>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableBody>
                            {photobooks.map((row) => {
                                const { id, name, Status } = row;

                                return (
                                    <TableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                        onClick={() => {
                                            photobookSelected(row);
                                        }}
                                        sx={{ userSelect: 'none', cursor: 'pointer' }}>
                                        <TableCell component="th" scope="row" padding="normal">
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="subtitle2" noWrap>
                                                    {id}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none">
                                            <Stack direction="row" alignItems="space-between" spacing={2}>
                                                <Typography variant="subtitle2" noWrap>
                                                    {name}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Label variant="ghost" color={'success'}>
                                                {sentenceCase(Status || 'success')}
                                            </Label>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    );
};

// src/auth/auth0-provider-with-history.js

import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import FullScreenLoading from 'components/FullScreenLoading';

const Auth0ProviderWithHistory = ({ children }: {children: ReactElement}) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';

    const navigate = useNavigate();

    const onRedirectCallback = (appState: AppState | undefined) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    const AuthAwait = ({children}: {children: ReactElement}) => {
        const a0 = useAuth0();
        return a0.isLoading ? <FullScreenLoading /> : children;
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
        >
            <AuthAwait>
                {children}
            </AuthAwait>
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
import { useAuth0 } from '@auth0/auth0-react';
import { API_BASE_PATH } from 'const';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { PhotobookMetadata } from 'types/PhotobookData';
import { DeviceUpdateSchema } from 'types/schemas/deviceUpdateRequest';
import { UpdatePhotobookDataRequest } from 'types/UpdatePhotobookDataRequest';
import { InferType } from 'yup';

const useMgmtApi = (photobookUrl: string) => {
    const [photobookData, setPhotobookData] = useState<PhotobookMetadata>();

    const { getAccessTokenSilently } = useAuth0();

    const [lastUpdate, setLastUpdate] = useState<number>(DateTime.now().toMillis());

    // --------------------------- RETRIEVE PHOTOBOOK DATA -----------------
    useEffect(() => {
        const loadPhotobook = async () => {
            const apiKey = await getAccessTokenSilently();

            const req = await fetch(new URL(`/photobooks/${photobookUrl}/info`, API_BASE_PATH), {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            });

            if (req.ok) {
                const res = await req.json();
                setPhotobookData(res);
            } else {
                throw new Error('Could not retrieve the photobook');
            }
        };

        loadPhotobook();
    }, [photobookUrl, lastUpdate, getAccessTokenSilently]);
    // --------------------------- END RETRIEVE PHOTOBOOK DATA -----------------

    const updateReq = async (method: string, url: string, data?: object) => {
        const apiKey = await getAccessTokenSilently();

        const options: RequestInit = {
            method,
            headers: {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json',
            },
        };

        if (data) {
            options.body = JSON.stringify(data);
        }

        const req = await fetch(new URL(url, API_BASE_PATH), options);

        if (req.ok) {
            setLastUpdate(DateTime.now().toMillis());
            return (await req.json()) || true;
        } else throw new Error(`Update failed: ${await req.text()}`);
    };

    const updatePhotobookData = (data: UpdatePhotobookDataRequest) => {
        return updateReq('POST', `/photobooks/${photobookData?.url}/update`, data);
    };

    const updateDeviceData = async (device_id: string, data: InferType<typeof DeviceUpdateSchema>) => {
        return updateReq('PATCH', `/devices/${device_id}`, data);
    };

    const removePhoto = async (id: number) => {
        return updateReq('POST', `/photos/${id}/unpublish`);
    };

    const getSidebarUploadLink = async (contentType: string) => {
        return updateReq('POST', `/photobooks/${photobookData?.url}/getWandSidebarUploadLink`, {
            contentType,
        });
    };

    const clearSidebarPhoto = async () => {
        return updateReq('POST', `/photobooks/${photobookData?.url}/update`, {
            wand_sidebar_cover: null,
        });
    };

    const pushPhoto = async (file: File, uploadUrl: string) => {
        const request = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
                'x-amz-acl': 'public-read',
            },
            body: file,
        });

        if (!request.ok) {
            throw new Error('Failed pushing file - ' + request.statusText);
        }

        return request;
    };

    return {
        photobookData,
        updatePhotobookData,
        updateDeviceData,
        removePhoto,
        updateReq,
        getSidebarUploadLink,
        pushPhoto,
        clearSidebarPhoto,
    };
};

export default useMgmtApi;

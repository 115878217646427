import Page from 'components/Page';

// mock
import { useNavigate } from 'react-router-dom';
import { PhotobookSelect } from 'sections/@dashboard/photobookSelect/PhotobookSelect';

const DashboardSelect = () => {
    const navigate = useNavigate();

    const photobookSelected = (photobookInfo) => {
        navigate(`/dashboard/${photobookInfo.url}`);
    };

    return (
        <Page title="Izberi dogodek">
            <PhotobookSelect photobookSelected={photobookSelected} />
        </Page>
    );
};

export default DashboardSelect;

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import AuthProvider from './components/auth/AuthProvider';
import ErrorBoundry from 'components/ErrorBoundry';

// ----------------------------------------------------------------------

export default function App() {
    return (
        <ThemeProvider>
            <AuthProvider>
                <>
                    <ScrollToTop />
                    <BaseOptionChartStyle />
                    <ErrorBoundry>
                        <Router />
                    </ErrorBoundry>
                </>
            </AuthProvider>
        </ThemeProvider>
    );
}

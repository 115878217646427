import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, SxProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------


export default function Logo({ disabledLink, sx }: {disabledLink?: boolean, sx?: SxProps<Theme>}) {



    // OR
    // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

    const logo = (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            <img src={'/static/logo.svg'} />
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}

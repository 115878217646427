import { API_BASE_PATH } from 'const';
import { PhotoEntryData } from 'types/PhotoEntryData';
import { useContext } from 'react';
import { PhotobookContext } from 'context/PhotobookContext';


export function useHeartsApi() {

    const { photobook } = useContext(PhotobookContext);

    const addHeart = async (heartCount: number, tile: PhotoEntryData) => {
        const imageId = tile.id + '';
        const noHearths = heartCount + '';

        await fetch(new URL(`/photobooks/${photobook?.url}/${imageId}/hearths`, API_BASE_PATH), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ noHearths })
        });
    };


    return {
        addHeart,
    };
}

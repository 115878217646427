import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// components
import Iconify from '../../components/Iconify';
import AuthenticationButton from 'components/auth/AuthButton';
import { useContext } from 'react';
import { MgmtContext } from 'context/MgmtContext';
//
/*import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';*/

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    borderBottom: '1px #444 solid',
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    const { photobookData } = useContext(MgmtContext);

    return (
        <RootStyle>
            <ToolbarStyle>
                <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>

                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.body1">VIBEMIRROR</Typography>
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        Dogodki
                    </Link>
                    <Typography color="text.primary">{photobookData?.name || 'Nov dogodek'}</Typography>
                </Breadcrumbs>

                {/*<Searchbar />*/}
                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    {/* <LanguagePopover />
          <NotificationsPopover />
  <AccountPopover /> */}
                    <AuthenticationButton />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}

// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

// ----------------------------------------------------------------------

const DebugRouter = ({ children }: { children: any }) => {
    const location = useLocation();
    if (process.env.NODE_ENV === 'development') {
        console.log(
            `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
        );
    }
  
    return children;
};

ReactDOM.render(
    <HelmetProvider>
        <BrowserRouter>
            <DebugRouter>
                <App />
            </DebugRouter>
        </BrowserRouter>
    </HelmetProvider>,
    document.getElementById('root')
);

// @mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------

export default function useResponsive(query: string, key?: string, start?: string, end?: string) {
    const theme = useTheme();

    const mediaUp = useMediaQuery(theme.breakpoints.up(key || theme.breakpoints['lg']));

    const mediaDown = useMediaQuery(theme.breakpoints.down(key || theme.breakpoints['lg']));

    const mediaBetween = useMediaQuery(theme.breakpoints.between(start || theme.breakpoints['sm'], end || theme.breakpoints['lg']));

    const mediaOnly = useMediaQuery(theme.breakpoints.only(key || theme.breakpoints['lg']));

    if (query === 'up') {
        return mediaUp;
    }

    if (query === 'down') {
        return mediaDown;
    }

    if (query === 'between') {
        return mediaBetween;
    }

    if (query === 'only') {
        return mediaOnly;
    }
    return null;
}

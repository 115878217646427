import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import NotFound from './pages/Page404';
import { ProtectedRoute } from 'components/auth/RequireAuth';
import React from 'react';
import DashboardSelect from 'pages/dashboard/DashboardSelect';
import PhotoSubmitLayout from 'layouts/PhotoSubmitLayout';
import LogoOnlyLayoutWithPadding from 'layouts/LogoOnlyLayoutWithPadding';
import EmptyLayout from 'layouts/EmptyLayout';
import Logout from 'pages/Logout';

// -----  DASHBOARD LAYOUT ----------------------------------------------

const DashboardPhotobookSettings = React.lazy(() => import('./pages/dashboard/DashboardPhotobookSettings'));
const DashboardQrCode = React.lazy(() => import('./pages/dashboard/DashboardQrCode'));
const DashboardDevices = React.lazy(() => import('./pages/dashboard/DashboardDevices'));
const DashboardDevicesNew = React.lazy(() => import('./pages/dashboard/DashboardDevicesNew'));
const DashboardUrl = React.lazy(() => import('./pages/dashboard/DashboardUrl'));
const DashboardPhotoRemove = React.lazy(() => import('./pages/dashboard/DashboardPhotoRemove'));
const DashboardDownloadAll = React.lazy(() => import('./pages/dashboard/DashboardDownloadAll'));
const DashboardHome = React.lazy(() => import('./pages/dashboard/DashboardHome'));
const DashboardBranding = React.lazy(() => import('./pages/dashboard/DashboardBranding'));

const DashboardDevicePairSelect = React.lazy(() => import('./pages/dashboard/DashboardDevicePairSelect'));

// ----------------------------------------------------------------------

// ------- PHOTOBOOK LAYOUT ---------------------------------------------

const PhotoSubmitScreen = React.lazy(() => import('./pages/submit/PhotoSubmitScreen'));

// ------- END PHOTOBOOK LAYOUT -----------------------------------------

// ------- DEMO LAYOUT ---------------------------------------------

const DemoServer = React.lazy(() => import('./pages/demo/DemoServer'));
const DemoClient = React.lazy(() => import('./pages/demo/DemoClient'));

// ------- END PHOTOBOOK LAYOUT -----------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/dashboard',
            element: <LogoOnlyLayoutWithPadding />,
            children: [
                { path: 'select', element: <ProtectedRoute component={DashboardSelect} /> },

                // If the router visits /dashboard, go to photobook selector
                { path: '', element: <Navigate to="select" replace /> },
            ],
        },
        {
            path: '/dashboard/:photobookUrl',
            element: <ProtectedRoute component={DashboardLayout} />,
            children: [
                {
                    path: 'discover',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardHome />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'settings',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardPhotobookSettings />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'branding',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardBranding />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'qr',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardQrCode />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'devices',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardDevices />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'devices/:deviceId',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardDevicesNew />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'openPhotobook',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardUrl />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'redact',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardPhotoRemove />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'downloadAll',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardDownloadAll />
                        </React.Suspense>
                    ),
                },

                // If the router visits /dashboard/:photobookId, go to the default dashboard screen
                { path: '', element: <Navigate to="discover" replace /> },
            ],
        },
        {
            path: '/m/pair/:pairingID',
            element: <ProtectedRoute component={LogoOnlyLayoutWithPadding} />,
            children: [
                {
                    path: '',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DashboardDevicePairSelect />
                        </React.Suspense>
                    ),
                },
            ],
        },
        {
            path: '/s/:photobookUrl',
            element: <PhotoSubmitLayout />,
            children: [
                {
                    path: '',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <PhotoSubmitScreen />
                        </React.Suspense>
                    ),
                },
            ],
        },
        {
            path: '/demo',
            element: <EmptyLayout />,
            children: [
                {
                    path: 'server',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DemoServer />
                        </React.Suspense>
                    ),
                },
                {
                    path: 'client/:photobookID',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <DemoClient />
                        </React.Suspense>
                    ),
                },
            ],
        },
        {
            path: '/demo/join/:photobookID',
            element: <PhotoSubmitLayout />,
            children: [
                {
                    path: '',
                    element: (
                        <React.Suspense fallback={<>...</>}>
                            <PhotoSubmitScreen />
                        </React.Suspense>
                    ),
                },
            ],
        },
        {
            path: '/logout',
            element: <Logout />,
        },
        {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
                // Default app route - should point to
                { path: '', element: <Navigate to="/dashboard/select" replace /> },

                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

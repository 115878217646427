import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import useUserApi from 'hooks/useUserApi';
import { useEffect } from 'react';
import { PhotobookMetadata } from 'types/PhotobookData';
import { PhotobookGrid } from './PhotobooksGrid';

export const PhotobookSelect = ({
    photobookSelected,
}: {
    photobookSelected: (pb: PhotobookMetadata | false) => void;
}) => {
    const { userData, isInitialized } = useUserApi();

    const photobooks = userData ? userData.photobooks : [];

    useEffect(() => {
        if (isInitialized) {
            // debugger;
            if (photobooks.length === 1) {
                photobookSelected(photobooks[0]);
            } /*else if (photobooks.length === 0) {
                photobookSelected(false);
            }*/
        }
    }, [photobooks, userData, isInitialized]);

    if (!isInitialized) {
        return <CircularProgress />;
    }

    if (photobooks.length === 0) {
        //
        return (
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    Trenutno na tvoj profil ni vezan še noben dogodek
                </Typography>
                <Box
                    component="img"
                    src="/static/illustrations/illustration_register.png"
                    sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                />
                <Typography variant="body2" gutterBottom>
                    Če si VIBEMIRROR naročil nedavno, le še malo počakaj, da ti dostavimo paket. Nato poskusi znova
                </Typography>
                <Typography variant="body2" gutterBottom sx={{ py: 3 }}>
                    Če VIBEMIRRORja še nisi naročil, te vabimo, da si ga priskrbiš.
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        window.location.href = 'https://vibemirror.com/#pricing';
                    }}>
                    Naroči VIBEMIRROR
                </Button>
            </Box>
        );
    }

    return (
        <>
            <Stack direction="column" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Odpri dogodek
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Iz seznama izberi dogodek, ki ga želiš urediti.
                </Typography>
            </Stack>
            <PhotobookGrid photobooks={photobooks} photobookSelected={photobookSelected} />;
        </>
    );
};

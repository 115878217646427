// src/components/login-button.js

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Button
            className="btn btn-primary btn-block"
            onClick={() => loginWithRedirect()}
        >
      Prijava
        </Button>
    );
};

export default LoginButton;
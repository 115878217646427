import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactElement } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

interface PageProps {
    children: ReactElement;
    title: string;
    meta?: any;
}

const PageFn = forwardRef(function Page({ children, title = '', meta, ...other }: PageProps, ref) {
    return (
        <>
            <Helmet>
                {/*<title>{`${title} | ${process.env.REACT_APP_PRODUCT_NAME}`}</title>*/}
                <title>{`${process.env.REACT_APP_PRODUCT_NAME}`}</title>
                {meta}
            </Helmet>

            <Box ref={ref} {...other}>
                {children}
            </Box>
        </>
    );
});

export default PageFn;

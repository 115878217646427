// ----------------------------------------------------------------------

export default function TableCell() {
    return {
        MuiTableCell: {
  
            styleOverrides: {
                root: {
                    border: '0px transparent solid'
                }
            }
        }
    };
}
  
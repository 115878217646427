// material
import { alpha, styled } from '@mui/material/styles';
// components
import { Box, Link, Typography } from '@mui/material';
import LogoSection from 'components/LogoSection';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function PublicAppBar() {
    const APPBAR_MOBILE = 64;
    const APPBAR_DESKTOP = 92;

    const HeaderStyle = styled('header')(({ theme }) => ({
        top: 0,
        left: 0,
        zIndex: 99,
        width: '100%',
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: APPBAR_MOBILE,
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        padding: '0 10px 0 0',
        boxShadow: (theme as any).customShadows.z8,
        backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
        [theme.breakpoints.up('md')]: {
            height: APPBAR_DESKTOP,
            padding: theme.spacing(0, 5),
        },
    }));

    const Placeholder = styled(Box)(({ theme }) => ({
        height: APPBAR_MOBILE,
        [theme.breakpoints.up('md')]: {
            height: APPBAR_DESKTOP,
        },
    }));

    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     navigate('/dashboard');
    // };

    return (
        <>
            <HeaderStyle>
                <Link href="https://vibemirror.com">
                    <LogoSection />
                </Link>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    {/*<Tooltip title="Vrni se na nadzorno ploščo">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
          >
            <Avatar sx={{ width: 32, height: 32 }}><Iconify icon="majesticons:door-exit" /></Avatar>
          </IconButton>
  </Tooltip>*/}
                </Box>
            </HeaderStyle>
            <Placeholder />
        </>
    );
}

import { io } from 'socket.io-client';
import { useState, useEffect } from 'react';

import { API_BASE_PATH } from 'const';
import { PhotobookData } from 'types/PhotobookData';
import { PhotoEntryData } from 'types/PhotoEntryData';
import { DateTime } from 'luxon';


export function usePhotobook(url: string) {
    const [ photobook, setPhotobook ] = useState<PhotobookData | null>(null);
    const [ isLoading, setIsLoading ] = useState(true);

    
    // ------------------------------- INIT - Load initial photos --------------------------

    useEffect(() => {

        const apiUrl = new URL(`/photobooks/${url}`, API_BASE_PATH);

        const fetchPhotobook = async () => {
            // console.log("Initial photo load...");
            setIsLoading(true);
            const req = await fetch(apiUrl);
            if (req.ok) {
                const data = await req.json() as PhotobookData;
                
                // Order them by falling IDs
                data.photos.sort(TileCompare);

                setPhotobook({...data});
            } else {
                throw new Error(`Photobook with url ${url} is unavailable.`);
            }
            
            setIsLoading(false);
        };

        if (!photobook || photobook.url !== url) {
            fetchPhotobook();
        }

    }, [url, photobook]);

    // ------------------------------- END INIT - Load initial photos --------------------------

    

    
    // ------------------------------- SETUP SOCKET - Handle updates ---------------------------

    useEffect(() => {

        const socket = io(API_BASE_PATH, {
            autoConnect: false
        });
    
        const photobookID = url;
    
        socket.auth = {
            connectionType: 'PHOTOBOOK_UPDATES',
            deviceID: 'CLIENTv2',
            photobookID,
        };

        /**
         * A primitive access tracking system.
         * 
         * Relies on the client to track if client viewed this photobook for the first time or not.
         */
        const trackingKey = `VIEWED_${url}`;
        if (localStorage.getItem(trackingKey) === null) {
            localStorage.setItem(trackingKey, DateTime.now().toMillis() + '');
            // Set the firstTimeHere flag to true
            socket.auth.firstTimeHere = true;
        }
    
        socket.connect();


        const photoChangeHandler = (data: PhotoEntryData) => {

            setPhotobook((pb: PhotobookData | null) => { 
                if (!pb) {return null;};

                const existing = pb.photos.findIndex(p => p.id === data.id);
                if (existing === -1) {
                    return ({
                        // Keep everything from the photobook
                        ...pb, 
                        // But add that new photo to the beginning
                        photos: [data, ...pb.photos]});
                } else {

                    const newPb = {
                        // Keep everything from the photobook
                        ...pb
                    };

                    // Replace
                    pb.photos[existing] = data;

                    return newPb;
                }
            });

        };

        const photoRemoveHandler = (data: {id: number}) => {
            
            setPhotobook((pb: PhotobookData | null) => { 
                if (!pb) {return null;};

                return ({
                    // Keep everything from the photobook
                    ...pb, 
                    // But remove that one photo.
                    photos: pb.photos.filter(photo => photo.id !== data.id)});
            });
        };


        /**
         * When a new photo is added
         */
        socket.on('photoAdded', photoChangeHandler);

        /**
         * When a photo changes
         */
        socket.on('photoChanged', photoChangeHandler);
    
        /**
         * When a photo is removed
         */
        socket.on('photoRemoved', photoRemoveHandler);


        return () => {
            // console.log("Destroying socketIO for " + url)
            socket.off('photoAdded', photoChangeHandler);
            socket.off('photoChanged', photoChangeHandler);
            socket.off('photoRemoved', photoRemoveHandler);
        };
    }, [url]);

    // ------------------------------- END SETUP SOCKET - Handle updates ---------------------------

    return { photobook, isLoading, setPhotobook };
}

export function TileCompare(a: PhotoEntryData, b: PhotoEntryData) {
    return b.id - a.id;
}


import { PhotobookContext } from 'context/PhotobookContext';
import { UploadApiContext } from 'context/UploadApiContext';
import { useHeartsApi } from 'hooks/useHeartsApi';
import { usePhotobook } from 'hooks/usePhotobook';
import useUploadApi from 'hooks/useUploadApi';
import { useParams, Outlet } from 'react-router-dom';
import PublicAppBar from './PublicAppBar';
import { HeartsApiContext } from 'context/HeartsApiContext';
import { ClientSettingsContext } from 'context/ClientSettingsContext';

const PhotoSubmitLayout = () => {
    const { photobookUrl } = useParams();

    // Params is always here, because the router ensures so.
    const photobook = usePhotobook(photobookUrl || '');

    return (
        <ClientSettingsContext.Provider value={{ uploadResolutionLimit: 1920 }}>
            <PhotobookContext.Provider value={photobook}>
                <UploadApiProvider>
                    <HeartsApiProvider>
                        {photobook.photobook?.features?.includes('BRANDING') ? <></> : <PublicAppBar />}
                        <Outlet />
                    </HeartsApiProvider>
                </UploadApiProvider>
            </PhotobookContext.Provider>
        </ClientSettingsContext.Provider>
    );
};

const UploadApiProvider = ({ children }: any) => {
    const uploadApi = useUploadApi();

    return <UploadApiContext.Provider value={uploadApi}>{children}</UploadApiContext.Provider>;
};

const HeartsApiProvider = ({ children }: any) => {
    const heartsApi = useHeartsApi();

    return <HeartsApiContext.Provider value={heartsApi}>{children}</HeartsApiContext.Provider>;
};

export default PhotoSubmitLayout;

import { useAuth0 } from '@auth0/auth0-react';
import { API_BASE_PATH } from 'const';
import { useEffect, useState } from 'react';
import { UserData } from 'types/UserData';

const useUserApi = () => {

    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const [userData, setUserData] = useState<UserData>();

    const { getAccessTokenSilently } = useAuth0();


    // --------------------------- RETRIEVE USER DATA ----------------------
    useEffect(() => {

        const getUserData = async () => {

            const apiKey = await getAccessTokenSilently();

            const resp = await fetch(new URL('users/me', API_BASE_PATH), {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${apiKey}`
                }
            });

            const data = await resp.json();

            setUserData(data);
            setIsInitialized(true);
        };

        if (!userData) {
            getUserData();
        }

    }, [userData, getAccessTokenSilently]);
    // --------------------------- END RETRIEVE USER DATA ----------------------


    return {userData, isInitialized};
};

export default useUserApi;
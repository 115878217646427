import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import { Box, Container, Stack } from '@mui/material';
import AuthenticationButton from 'components/auth/AuthButton';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function LogoOnlyLayoutWithPadding({fixed}: {fixed?: boolean}) {

    const HeaderStyle = styled('header')(({ theme }) => ({
        top: 0,
        left: 0,
        lineHeight: 0,
        width: '100%',
        position: fixed ? 'fixed' : 'absolute',
        padding: theme.spacing(3, 3, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5, 5, 0),
        },
    }));

    const ContentStyle = styled('div')(({ theme }) => ({
    
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: theme.spacing(12, 0)
    }));

    return (
        <>
            <HeaderStyle>
                <Stack direction={'row'}>
                    <Logo disabledLink />
                    <Box sx={{flex: 1}} />
                    <AuthenticationButton />
                </Stack>
            </HeaderStyle>
            <Container>
                <ContentStyle>
                    <Outlet />
                </ContentStyle>
            </Container>
        </>
    );
}

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ErrorScreen() {
    return (
        <Page title="404">
            <Container>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" paragraph>
                        Ups, nekaj je šlo narobe.
                    </Typography>

                    <Typography sx={{ color: 'text.secondary' }}>
                        Program je naletel na napako. Prosim poskusi osvežiti stran, če pa to ne pomaga, pa nas prosim
                        obvesti na info@vibemirror.com
                    </Typography>

                    <Box
                        component="img"
                        src="/static/illustrations/illustration_register.png"
                        sx={{ height: 300, mx: 'auto', my: { xs: 5, sm: 10 } }}
                    />

                    <Button to="/" size="large" variant="contained" component={RouterLink}>
                        Nazaj na prvo stran
                    </Button>
                </ContentStyle>
            </Container>
        </Page>
    );
}

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
    return (
        <Page title="404">
            <Container>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" paragraph>
                        Ojla, na tem naslovu pa žal ni ničesar.
                    </Typography>

                    <Typography sx={{ color: 'text.secondary' }}>
                        Če si sam/a vpisal/a ta spletni naslov, preveri črkovanje. Če si bil/a na to stran
                        preusmerjen/a, se ti opravičujemo - nekaj bo narobe. O tej napaki nas lahko obvestiš na
                        info@vibemirror.com.
                    </Typography>

                    <Box
                        component="img"
                        src="/static/illustrations/illustration_404.svg"
                        sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                    />

                    <Button to="/" size="large" variant="contained" component={RouterLink}>
                        Nazaj na prvo stran
                    </Button>
                    <Button to="https://vibemirror.com" size="large" variant="contained" component={RouterLink}>
                        Izvedi več o VIBEMIRROR
                    </Button>
                </ContentStyle>
            </Container>
        </Page>
    );
}

import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer, Typography } from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';

import { MgmtContext } from 'context/MgmtContext';
import LogoSection from 'components/LogoSection';
import Scrollbar from 'components/Scrollbar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey['500_12'],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    const { photobookData } = useContext(MgmtContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}>
            {/** ----------------------------------------------------------- */}
            {/** ----------------------------------------------------------- */}
            {/** LOGO SECTION */}
            <LogoSection />
            {/** LOGO SECTION */}
            {/** ----------------------------------------------------------- */}
            {/** ----------------------------------------------------------- */}

            {/** ----------------------------------------------------------- */}
            {/** ----------------------------------------------------------- */}
            {/** Username section */}
            <Box sx={{ mb: 5, mx: 2.5 }}>
                <AccountStyle>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {photobookData?.name}
                        </Typography>
                        <Button
                            sx={{ fontSize: '11px' }}
                            variant="outlined"
                            onClick={() => {
                                navigate('/dashboard/select');
                            }}>
                            Odpri drug dogodek
                        </Button>
                    </Box>
                </AccountStyle>
            </Box>
            {/** Username SECTION */}
            {/** ----------------------------------------------------------- */}
            {/** ----------------------------------------------------------- */}

            <NavSection
                navConfig={navConfig.filter(
                    (b) => !b.requiredFeature || photobookData?.features.includes(b.requiredFeature)
                )}
            />

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <RootStyle>
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}>
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}>
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}

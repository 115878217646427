// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------


export default function Iconify({ icon, sx, ...other }: {icon: string, sx?: any, [key: string]: any}) {
    return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}

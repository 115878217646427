import { useState, useEffect, useContext } from 'react';

import { API_BASE_PATH } from 'const';
import { UploadSessionInfo } from 'types/UploadSessionInfo';
import { PhotoEntryData } from 'types/PhotoEntryData';
import { PhotobookContext } from 'context/PhotobookContext';

export function useUploadApi() {
    const { photobook, setPhotobook } = useContext(PhotobookContext);
    const [newPhotoItem, setNewPhotoItem] = useState<PhotoEntryData | null>();

    const url: string = photobook?.url || '';

    /**
     * The main upload method.
     * 1. Begins the upload session on Strapi
     * 2. Pushes the photo to S3
     * 4. Finishes the upload session on Strapi
     *
     * @param file The file to push to VibeMirror
     * @returns
     */
    const uploadPhotoAsync = async (file: File) => {
        const sessionInfo = await beginUploadSession(file, url);
        await pushPhoto(file, sessionInfo);
        await finishUploadSession(sessionInfo);

        setNewPhotoItem({
            id: sessionInfo.photoId,
            hearths: 0,
            background: '',
            created_at: new Date().toISOString(),
            description: '',
            image: {
                id: -256,
                formats: {
                    medium: {
                        size: file.size,
                        width: 1000,
                        height: 1000,
                        mime: file.type,
                        url: URL.createObjectURL(file),
                    },
                } as any,
            },
        });

        return { sessionId: sessionInfo.descriptionChangeSessionId, photoId: sessionInfo.photoId };
    };

    useEffect(() => {
        if (newPhotoItem && photobook && photobook.photos.filter((p) => p.id === newPhotoItem.id).length === 0) {
            setPhotobook({
                ...photobook,
                photos: [newPhotoItem, ...photobook.photos],
            });
            setNewPhotoItem(null);
        }
    }, [newPhotoItem, photobook]);

    const updateDescription = async (sessionId: string, description: string, photoId: number) => {
        await fetch(new URL(`/photobooks/${url}/finish/${sessionId}`, API_BASE_PATH), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: new URLSearchParams({ description }),
        });

        if (photobook) {
            const pb = { ...photobook };
            const i = pb.photos.filter((p) => p.id === photoId);
            if (i.length === 1) {
                i[0].description = description;
                setPhotobook(pb);
            }
        }
    };

    return {
        uploadPhotoAsync,
        updateDescription,
        photobook,
    };
}

const beginUploadSession = async (file: File, photobookUrl: string) => {
    const request = await fetch(new URL('/photos/beginUpload', API_BASE_PATH), {
        method: 'POST',
        body: JSON.stringify({
            photobookId: photobookUrl,
            contentType: file.type,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!request.ok) {
        throw new Error('Failed starting upload session - ' + request.statusText);
    }

    return (await request.json()) as UploadSessionInfo;
};

const pushPhoto = async (file: File, sessionInfo: UploadSessionInfo) => {
    const request = await fetch(sessionInfo.uploadUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type,
            'x-amz-acl': 'public-read',
        },
        body: file,
    });

    if (!request.ok) {
        throw new Error('Failed pushing file - ' + request.statusText);
    }

    return request;
};

const finishUploadSession = async (sessionInfo: UploadSessionInfo) => {
    const request = await fetch(sessionInfo.callbackUrl, {
        mode: 'cors',
        method: 'POST',
        body: sessionInfo.callbackToken,
    });

    if (!request.ok) {
        throw new Error('Failed finishing the upload - ' + request.statusText);
    }

    return request;
};

export default useUploadApi;

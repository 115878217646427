import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useMgmtApi from 'hooks/useMgmtApi';
import { MgmtContext } from 'context/MgmtContext';
import FullScreenLoading from 'components/FullScreenLoading';
import ErrorBoundry from 'components/ErrorBoundry';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);

    // The router ensures that the photobookId is always set.
    // It may be invalid though
    const { photobookUrl } = useParams();
    const mgmt = useMgmtApi(photobookUrl as string);

    if (!mgmt.photobookData) {
        return <FullScreenLoading />;
    }

    return (
        <RootStyle>
            <MgmtContext.Provider value={mgmt}>
                <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
                <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
                <MainStyle>
                    <ErrorBoundry>
                        <Outlet />
                    </ErrorBoundry>
                </MainStyle>
            </MgmtContext.Provider>
        </RootStyle>
    );
}

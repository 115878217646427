// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
    {
        title: 'Moj VibeMirror',
        path: 'discover',
        icon: getIcon('ic:baseline-fiber-new'),
    },
    {
        title: 'PRED DOGODKOM',
        type: 'sectionTitle',
    },
    {
        title: 'Nastavitve dogodka',
        path: 'settings',
        icon: getIcon('eva:settings-outline'),
    },
    {
        title: 'Branding',
        path: 'branding',
        requiredFeature: 'BRANDING',
        icon: getIcon('eva:brush-outline'),
    },
    /*{
        title: 'Tiskanje QR Kode',
        path: 'qr',
        icon: getIcon('bi:qr-code'),
    },*/
    {
        title: 'Naprave',
        path: 'devices',
        icon: getIcon('eva:monitor-fill'),
    },
    {
        title: 'MED DOGODKOM',
        type: 'sectionTitle',
    },
    {
        title: 'Odpri foto knjigo',
        path: 'openPhotobook',
        icon: getIcon('eva:book-open-fill'),
    },
    {
        title: 'Odstranjevanje slik',
        path: 'redact',
        icon: getIcon('eva:file-remove-fill'),
    },
    {
        title: 'PO DOGODKU',
        type: 'sectionTitle',
    },
    {
        title: 'Prenos slik',
        path: 'downloadAll',
        icon: getIcon('eva:cloud-download-fill'),
    },
];

export default navConfig;
